<template>
    <v-container grid-list-lg fluid>
        <v-layout row wrap>
            <v-flex lg12>
                <v-card>
                    <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                    <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                            <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td v-if="item.OrderType == 1"><a target="_blank" :href="'/don-hang/' + item.OrderID">MD{{item.OrderID}}</a></td>
                                    <td v-else><a target="_blank" :href="'/ky-gui/' + item.OrderID">KG{{item.OrderID}}</a></td>
                                    <td>{{ item.PurchaseName }}</td>
                                    <td>{{ item.BuyingDate | display_date('DD-MM-YYYY')}}</td>
                                    <!-- <td class="text-end">{{ item.StandbyDay | currency }}</td> -->
                                    <td><span v-if="item.FlagID > 0"><v-icon medium :color="getFlagColor(item.FlagID)">flag</v-icon> {{ getFlagName(item.FlagID) }}</span></td>
                                </tr>
                            </template>
                    </v-data-table>
                </v-card>
            </v-flex>
    </v-layout>
    </v-container>
</template>
<script>
import { mapState } from "vuex";
import configs from "@/configs";
import Filtering from "@/components/Filtering";
import Vue from 'vue';
import _ from "lodash";
export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            viewName: "flag_kiemsoatdonhang",
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            filterConditions: [],
            quickFilterConditions: [],
            headers: [
                {
                text: "Mã đơn hàng",
                value: "OrderID",
                quickSearch: true,
                filterable: true,
                dataType: configs.DATA_TYPE["Number"]
                },
                {
                text: "CSKH",
                value: "PurchaseName",
                quickSearch: true,
                filterable: true,
                dataType: configs.DATA_TYPE["String"]
                },
                {
                text: "Ngày mua hàng",
                value: "BuyingDate",
                filterable: true,
                dataType: configs.DATA_TYPE["Date"]
                },
                // {
                // text: "Số ngày trễ",
                // value: "StandbyDay",
                // align: "end",
                // filterable: true,
                // dataType: configs.DATA_TYPE["Number"]
                // },
                {
                text: "Hashtag",
                value: "FlagID",
                filterable: true,
                dataType: configs.DATA_TYPE["Enum"],
                values: []
                }    
            ]
        };
    },
    computed: {
        ...mapState({
            items: state => state.report.orderWarning.data,
            total_rows: state => state.report.orderWarning.total,
            loading: state => state.report.loading,
            flag_loading: state => state.flag.loading,
            allFlags: state => _.map(state.flag.all.data, (item) => {
                return {
                    value: item.ID,
                    name: item.FlagName,
                    color: item.FlagColor
                }
            })
        }),
        flagSource() {
            let tmp = this.allFlags ? [
                ...this.allFlags
            ] : [];
            tmp.push(
            {
                value: 0,
                name: '<Không gắn hashtag>'
            });
            return tmp;
        }
    },
    watch: {
        pagination: function(val) {
            let filterConditions = this.$store.state.commons.filterConditions;
            this.filterConditions = !_.isEmpty(filterConditions)
                ? filterConditions[this.viewName]
                : [];
            this.filter_data();
        },
        flag_loading() {
            let header_item = _.find(this.headers, {value: "FlagID"});
            if(header_item) {
                header_item.values = this.flagSource || []
            }
        }
    },
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("report/getOrderWarning", param);
            this.$store.dispatch("flag/getList");
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        getFlagName(id) {
            let flag = _.find(this.flagSource, {value: id});
            return flag ? flag.name : '';
        },
        getFlagColor(id) {
            let flag = _.find(this.flagSource, {value: id});
            return flag ? flag.color : '';
        }
    },
    mounted() {
        this.filterConditions = [];
        this.quickFilterConditions = [];
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>